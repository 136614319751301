import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import axios, { CancelToken } from "axios";
import { v4 as uuidv4 } from "uuid";
import { Box, useMediaQuery, Typography, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import background from "../imges/background.png";
import AppStore from "../components/StyledComponents/AppStore";
import Paper from "@mui/material/Paper";
import FlexCenter from "../components/StyledComponents/FlexCenter";
import { useTheme } from "@mui/material/styles";
import FlexBetween from "../components/StyledComponents/FlexBetween";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddFileButton from "../components/FileUploadingPageComponents/AddFileButton";
import CompressingComponents from "../components/FileUploadingPageComponents/CompressingComponents";
import ComprssedComponents from "../components/FileUploadingPageComponents/ComprssedComponents";
import FileAddingComponents from "../components/MoblieResponsiveFileUploadingPage/FileAddingComponents";
import MobileCompressingComponents from "../components/MoblieResponsiveFileUploadingPage/MobileCompressingComponents";
import MobileCompressedComponents from "../components/MoblieResponsiveFileUploadingPage/MobileCompressedComponents";
import VideoIcon from "../components/FileUploadingPageComponents/VideoIcon";
// import { onMessage } from "firebase/messaging";
// import { messaging } from "../firebase";
import DisplayNotification from "../helper/notificationHelper";

const Item = (props) => (
  <Paper
    sx={{
      justifyContent: "flex-start",
      padding: "16px 15%",
      borderRadius: "0px",
      ...props.sx,
    }}
  >
    {props.children}
  </Paper>
);

const styles = {
  root: {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "5rem 4% 3% 4%",
  },
  text: {
    color: "white",
    textAlign: "center",
  },
};

function FileUploadingPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobileScreen = useMediaQuery("(min-width:1000px)");
  // const [socket, setSocket] = useState(null);
  const [multipleFilesSelected, setMultipleFilesSelected] = useState([]);
  const [isFileCompressing, setIsFileCompressing] = useState(false);
  const [isFileAdding, setIsFileAdding] = useState(false);
  const [isFileCompressed, setIsFileCompressed] = useState(false);
  const [fileProgress, setFileProgress] = useState({});
  const [fileUploadingProgress, setFileUploadingProgress] = useState({});
  const [compressedResult, setCompressedResult] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [cancelTokenSources, setCancelTokenSources] = useState([]);
  // const [notification, setNotification] = useState({ title: '', body: '' });

  const handelMultipleFilesSelected = (file) => {
    setMultipleFilesSelected((prevFiles) => [...prevFiles, file]);
  };

  const onFilesSelected = async (selectedFile) => {
    handelMultipleFilesSelected(selectedFile);
  };

  // const handleRemoveFile = (index) => {
  //   setMultipleFilesSelected((prevFiles) => {
  //     const newFiles = [...prevFiles];
  //     newFiles.splice(index, 1);

  //     // Check if the updated array is empty
  //     if (newFiles.length === 0) {
  //       // Wrap the navigation logic in setTimeout to delay it
  //       setTimeout(() => {
  //         navigate("/videocompressor");
  //       }, 0);
  //     }

  //     return newFiles;
  //   });
  // };

  const handleRemoveFile = (index) => {
    // Find the cancellation token source for the file at the specified index
    const cancelTokenSource = cancelTokenSources[index];

    // Cancel the corresponding Axios request
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled by user");
    }

    setMultipleFilesSelected((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);

      // Check if the updated array is empty
      if (newFiles.length === 0) {
        // Wrap the navigation logic in setTimeout to delay it
        setTimeout(() => {
          navigate("/videocompressor");
        }, 0);
      }

      return newFiles;
    });
  };

  // const handleUploadAndCompression = async (files) => {
  //   setIsFileAdding(false);
  //   setIsFileCompressing(true);

  //   try {
  //     const promises = files.map(async (file) => {
  //       //  console.log(`files-new-${(file.uid)}`);
  //       // Generate a UUID
  //       const uniqueId = uuidv4();
  //       const formData = new FormData();
  //       formData.append(uniqueId, file);
  //       // formData.append("uuid", uniqueId);

  //       const response = await axios.post(
  //         `${process.env.REACT_APP_BASE_URL}/uploadsandcompress`,
  //         formData,
  //         {
  //           onUploadProgress: (progressEvent) => {
  //             const progressPercent =
  //               (progressEvent.loaded / progressEvent.total) * 100;

  //             // Update the progress for the specific file in state here
  //             setFileUploadingProgress((prevProgress) => {
  //               // console.log(
  //               //   `Upload Progress for ${file.name}: ${progressPercent.toFixed(
  //               //     2
  //               //   )}%`
  //               // );
  //               return {
  //                 ...prevProgress,
  //                 [file.name]: progressPercent.toFixed(2),
  //               };
  //             });
  //             // console.log(
  //             //   `Upload Progress for ${file.name}`
  //             // );
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         const contentType = response.headers["content-type"];
  //         if (contentType && contentType.includes("application/json")) {
  //           const result = response;
  //           const completedFile = multipleFilesSelected.find(
  //             (uploadingFile) =>
  //               uploadingFile.name === result.data.file.filename
  //           );
  //           setMultipleFilesSelected((prevFiles) =>
  //             prevFiles.filter(
  //               (uploadingFile) =>
  //                 uploadingFile.name !== result.data.file.filename
  //             )
  //           );
  //           if (completedFile) {
  //             setUploadedFiles((prevFiles) => [...prevFiles, completedFile]);
  //           }
  //           console.log(`result-${JSON.stringify(result.data.file)}`);
  //           setCompressedResult((prevFiles) => {
  //             const originalFileSize = isNaN(
  //               result.data.file.originalFileSize
  //             )
  //               ? (file.size / (1024 * 1024)).toFixed(2)
  //               : result.data.file.originalFileSize;
  //             return [
  //               ...prevFiles,
  //               {
  //                 file: {
  //                   ...result.data.file,
  //                   originalFileSize: originalFileSize,
  //                 },
  //               },
  //             ];
  //           });
  //           //   if (Array.isArray(result.files)) {
  //           //     const urlArray = result.files.map((item) => item);
  //           //     setCompressedResult(urlArray);
  //           //   } else {
  //           //     console.error("Result is not an array:", result);
  //           //     // Handle the case where result is not an array appropriately
  //           //   }
  //         } else {
  //           console.error("Invalid Content-Type:", contentType);
  //         }
  //       }
  //     });
  //     const allResponse = await Promise.all(promises);
  //     if (allResponse) {
  //       setIsFileCompressing(false);
  //       setIsFileCompressed(true);
  //     } else {
  //       console.log(`error in all response`);
  //     }
  //   } catch (error) {
  //     console.error("Error during upload and compression:", error);
  //   }
  // };

  const handleUploadAndCompression = async (files) => {
    setIsFileAdding(false);
    setIsFileCompressing(true);

    try {
      const fcmToken = localStorage.getItem("fcmToken"); // Retrieve the FCM token from local storage
      const promises = files.map(async (file, index) => {
        const source = CancelToken.source();
        setCancelTokenSources((prevSources) => [...prevSources, source]);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("fcmToken", fcmToken);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/uploadsandcompress`,
            formData,
            {
              onUploadProgress: (progressEvent) => {
                const progressPercent =
                  (progressEvent.loaded / progressEvent.total) * 100;
                setFileUploadingProgress((prevProgress) => ({
                  ...prevProgress,
                  [file.name]: progressPercent,
                }));
              },
              cancelToken: source.token,
            }
          );

          if (response.status === 200) {
            const contentType = response.headers["content-type"];
            if (contentType && contentType.includes("application/json")) {
              const result = response;
              const completedFile = multipleFilesSelected.find(
                (uploadingFile) =>
                  uploadingFile.name === result.data.file.filename
              );
              setMultipleFilesSelected((prevFiles) =>
                prevFiles.filter(
                  (uploadingFile) =>
                    uploadingFile.name !== result.data.file.filename
                )
              );
              if (completedFile) {
                setUploadedFiles((prevFiles) => [...prevFiles, completedFile]);
              }
              console.log(`result-${JSON.stringify(result.data.file)}`);
              setCompressedResult((prevFiles) => {
                const originalFileSize = isNaN(
                  result.data.file.originalFileSize
                )
                  ? (file.size / (1024 * 1024)).toFixed(2)
                  : result.data.file.originalFileSize;
                return [
                  ...prevFiles,
                  {
                    file: {
                      ...result.data.file,
                      originalFileSize: originalFileSize,
                    },
                  },
                ];
              });
            } else {
              console.error("Invalid Content-Type:", contentType);
            }
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log("Request canceled:", error.message);
          } else {
            console.error("Error during upload and compression:", error);
          }
        }
      });

      const allResponse = await Promise.all(promises);
      if (allResponse) {
        setIsFileCompressing(false);
        setIsFileCompressed(true);
      } else {
        console.log(`error in all response`);
      }
    } catch (error) {
      console.error("Error during upload and compression:", error);
    }
  };

  // noti
  // useEffect(() => {
  //   const unsubscribe = onMessage(messaging, (payload) => {
  //     console.log("Message received: ", payload);
  //     // Handle the incoming message here, for example, display a notification
  //     const { title, body } = payload.notification;
  //     setNotification({ title, body });
  //     // alert(`Notification received: ${payload.notification.title} - ${payload.notification.body}`);
  //   });

  //   return () => unsubscribe();
  // }, []);
  // noti

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_BASE_URL}`);
    socket.on("compression-progress", ({ filename, progress }) => {
      setFileProgress((prevProgress) => {
        // Using the functional form to ensure the latest state is used
        return {
          ...prevProgress,
          [filename]: progress,
        };
      });
      // Move the console.log inside the setFileProgress callback
      // console.log(
      //   `data:${JSON.stringify(data)} and fileProgress:${JSON.stringify(
      //     fileProgress
      //   )}`
      // );
    });

    socket.on("compression-completed", ({ filename }) => {
      // console.log(`filename:${filename}`);
      // setCompressedFile(filename);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    // Check if location is defined
    if (location && location.state) {
      // Check if location.state is defined and contains the necessary data
      // console.log(location.state);
      const { selectedFile } = location.state;
      // console.log(selectedFile);
      handelMultipleFilesSelected(selectedFile);
      setIsFileAdding(true);
    } else {
      // Handle the case where location is not defined
      console.error("Location is not defined");
    }
  }, [location]);

  //  // Use this effect to navigate to "videocompressor" on every page refresh
  //    useEffect(() => {
  //     const handleBeforeUnload = () => {
  //       // Save a flag in localStorage to indicate the redirection
  //       localStorage.setItem("redirectToVideocompressor", "true");
  //     };

  //     // Add event listener for beforeunload
  //     window.addEventListener("beforeunload", handleBeforeUnload);

  //     // Remove the event listener when the component unmounts
  //     return () => {
  //       window.removeEventListener("beforeunload", handleBeforeUnload);
  //     };
  //   }, []);

  //    // Use this effect to check localStorage and navigate if needed
  //    useEffect(() => {
  //     const redirectToVideocompressor = localStorage.getItem(
  //       "redirectToVideocompressor"
  //     );

  //     if (redirectToVideocompressor === "true") {
  //       // Remove the flag from localStorage
  //       localStorage.removeItem("redirectToVideocompressor");

  //       // Navigate to "videocompressor" page
  //       navigate("/videocompressor");
  //     }
  //   }, [navigate]);

  return (
    <>
      <Box style={styles.root}>
        <Header />
        <FlexCenter>
          <Typography
            sx={{
              color: "black",
              fontSize: isNonMobileScreen ? "2.687rem" : "24px",
              fontWeight: "600",
              wordWrap: "break-word",
            }}
            variant={theme.typography.h2}
          >
            {isFileAdding && "Files "}
            {isFileCompressing && "Compression is "}
            {isFileCompressed && "Compressed "}
            {/* Files{" "} */}
            <span
              style={{
                background:
                  "linear-gradient(to right, rgba(88, 14, 228, 1), rgba(224, 102, 251, 1))",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              {isFileAdding && "Added Successfully "}
              {isFileCompressing && "Processing"}
              {isFileCompressed && "video Successfully"}
            </span>
          </Typography>
        </FlexCenter>
        {uploadedFiles.length > 1 && isNonMobileScreen && (
          <>
            <FlexCenter>
              <Typography
                sx={{
                  color: "#727CF5",
                  fontSize: isNonMobileScreen ? "24px" : "12px",
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
                variant={theme.typography.h2}
              >
                Total Files:
                <span
                  style={{
                    color: "black",
                  }}
                >
                  {uploadedFiles.length}
                </span>
              </Typography>
            </FlexCenter>
          </>
        )}

        <Box mt="16px">
          {!isNonMobileScreen && isFileAdding && (
            <FileAddingComponents
              multipleFilesSelected={multipleFilesSelected}
              handleRemoveFile={handleRemoveFile}
              handleUploadAndCompression={handleUploadAndCompression}
              onFilesSelected={onFilesSelected}
            />
          )}
          {isNonMobileScreen && isFileAdding && (
            <>
              <FlexCenter>
                <Box
                  sx={{
                    width: "75%",
                    background: "white",
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
                    borderRadius: "5px",
                    flexWrap: "nowrap",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "3rem 0px",
                  }}
                >
                  <Box
                    sx={{
                      width: "80%",
                      background: "white",
                      boxShadow:
                        "0px 2px 15.100000381469727px 2px rgba(0, 0, 0, 0.10)",
                      borderRadius: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      padding: "1rem 0px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "90%",
                        // minHeight: isNonMobileScreen ? "60%" : "85%",
                        borderTopLeftRadius: "33px",
                        borderTopRightRadius: "33px",
                        border: "1px rgba(0, 0, 0, 0.25) solid",
                        // position: "relative",
                      }}
                    >
                      <Grid
                        container
                        // direction="row"
                        // justifyContent="flex-start"
                        // alignItems="flex-end"
                        width="100%"
                        height="100%"
                        // position="absolute"
                        box-sizing="border-box"
                      >
                        <Grid item lg={12} border="none">
                          <Item
                            sx={{
                              borderTopLeftRadius: "33px",
                              borderTopRightRadius: "33px",
                              border: "none",
                              // background: "rgba(114, 124, 245, 0.10)",
                              color: "rgba(0, 0, 0, 0.50)",
                              boxShadow: "none",
                              // width:"200px",
                              padding: "0px 0px 0px 0px",
                            }}
                          >
                            <AddFileButton onFilesSelected={onFilesSelected} />
                          </Item>
                        </Grid>
                        {multipleFilesSelected.length > 0 &&
                          multipleFilesSelected.map((file, index) => (
                            <React.Fragment key={index}>
                              <Grid item lg={6} border="none">
                                <Item
                                  sx={{
                                    borderTopLeftRadius: "33px",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.50)",
                                    boxShadow: "none",
                                  }}
                                >
                                  <Box sx={{ position: "relative" }}>
                                    <VideoIcon left={"-45px"} />
                                    <Typography
                                      sx={{
                                        color: "black",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        wordWrap: "break-word",
                                        ml: 1,
                                      }}
                                      variant={theme.typography.h5}
                                    >
                                      {file.name}
                                    </Typography>
                                  </Box>
                                </Item>
                              </Grid>

                              <Grid item lg={2} border="none">
                                <Item
                                  sx={{
                                    border: "none",
                                    boxShadow: "none",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "black",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      wordWrap: "break-word",
                                    }}
                                    variant={theme.typography.h5}
                                  >
                                    {/* (data.compressedFileSize / (1024 * 1024)).toFixed(2) */}
                                    {(file.size / (1024 * 1024)).toFixed(2)}MB
                                  </Typography>
                                </Item>
                              </Grid>

                              <Grid item lg={3} border="none">
                                <Item
                                  sx={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                    border: "none",
                                    boxShadow: "none",
                                  }}
                                >
                                  <Box>
                                    <FlexBetween>
                                      <Typography
                                        sx={{
                                          color: "black",
                                          fontSize: "16px",
                                          fontWeight: "400",
                                          wordWrap: "break-word",
                                        }}
                                        variant={theme.typography.h5}
                                      >
                                        Output:
                                      </Typography>
                                      <Box
                                        textAlign="center"
                                        border="1px rgba(0, 0, 0, 0.50) solid"
                                        borderRadius="5px"
                                        color="blue"
                                        p="2px 2px"
                                      >
                                        <Typography>MP4</Typography>
                                      </Box>
                                    </FlexBetween>
                                  </Box>
                                </Item>
                              </Grid>

                              <Grid item lg={1} border="none">
                                <Item
                                  sx={{
                                    borderTopRightRadius: "33px",
                                    color: "rgba(0, 0, 0, 0.50)",
                                    border: "none",
                                    boxShadow: "none",
                                  }}
                                  // onClick={handleRemoveFile(index)}
                                  // onClick={() => removeFile(index)}
                                >
                                  <Box>
                                    <CancelRoundedIcon
                                      sx={{
                                        fontSize: "26px",
                                        transition:
                                          "transform 0.3s ease-in-out",
                                        "&:hover": {
                                          cursor: "pointer",
                                          color: "red",
                                          transform: "scale(1.2)",
                                        },
                                      }}
                                      onClick={() => handleRemoveFile(index)}
                                    />
                                  </Box>
                                </Item>
                              </Grid>
                            </React.Fragment>
                          ))}
                        <Grid item lg={8} border="none">
                          <Item
                            sx={{
                              background: "rgba(114, 124, 245, 0.10)",
                              color: "rgba(0, 0, 0, 0.50)",
                              border: "none",
                              boxShadow: "none",
                            }}
                          >
                            Added {multipleFilesSelected.length} Files
                          </Item>
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          border="none"
                          // onClick={handleUploadAndCompression(multipleFilesSelected)}
                          onClick={() =>
                            handleUploadAndCompression(multipleFilesSelected)
                          }
                          sx={{
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Item
                            sx={{
                              background: "#727cf5",
                              border: "none",
                              boxShadow: "none",
                            }}
                          >
                            <Typography
                              variant={theme.typography.h6}
                              sx={{
                                wordWrap: "break-word",
                                fontWeight: "400",
                                color: "white",
                              }}
                            >
                              Compress Now
                            </Typography>
                          </Item>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </FlexCenter>
            </>
          )}
          {!isNonMobileScreen && isFileCompressing && (
            <MobileCompressingComponents
              multipleFilesSelected={multipleFilesSelected}
              fileUploadingProgress={fileUploadingProgress}
              fileProgress={fileProgress}
              uploadedFiles={uploadedFiles}
              handleRemoveFile={handleRemoveFile}
            />
          )}

          {isNonMobileScreen && isFileCompressing && (
            <CompressingComponents
              multipleFilesSelected={multipleFilesSelected}
              fileUploadingProgress={fileUploadingProgress}
              fileProgress={fileProgress}
              uploadedFiles={uploadedFiles}
              handleRemoveFile={handleRemoveFile}
            />
          )}
          {!isNonMobileScreen && isFileCompressed && (
            <MobileCompressedComponents compressedResult={compressedResult} />
          )}

          {/* {isNonMobileScreen &&
            isFileCompressed &&
            compressedResult.map((file, index) => (
              //  console.log(`akash-${JSON.stringify(file)} and index-${index}`)
              <ComprssedComponents
                key={index}
                filename={file.filename}
                originalfilesize={file.original_file_size}
                compressedfilesize={file.compressed_file_size}
                finalUrl={file.url}
              />
            ))} */}
          {isNonMobileScreen &&
            isFileCompressed &&
            compressedResult.map((fileObject, index) => (
              <Box key={index} mb={4}>
                <ComprssedComponents
                  filename={fileObject.file.filename}
                  originalfilesize={fileObject.file.originalFileSize}
                  compressedfilesize={fileObject.file.compressedFileSize}
                  finalUrl={fileObject.file.url}
                />
              </Box>
            ))}
        </Box>
        <Box p="3rem 2%">
          <AppStore />
        </Box>
      </Box>
      {/* noti */}
      {/* <Box>
        <DisplayNotification
          title={notification.title} body={notification.body}
        />
      </Box> */}
      {/* end noti */}
      <Footer />
    </>
  );
}

export default FileUploadingPage;
