import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import Header from "../components/header";
import ChoseFile from "../components/ChoseFile";
import FigmaDiv from "../components/StyledComponents/FigmaDiv";
import Footer from "../components/footer";
import Blog from "../components/blog";
// import PricingPlan from "../components/pricingPlan";
import Arrow from "../components/StyledComponents/Arrow";
// import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
// import AddMoreButton from "../components/StyledComponents/AddMoreButton";
// import { getToken,onMessage } from "firebase/messaging";
// import { messaging } from "../firebase";
// import axios from "axios";

function VideoCompressor() {
  const isNonMobileScreen = useMediaQuery("(min-width:1000px)");
  // const [scrollPercent, setScrollPercent] = useState(0);
  // const [apiCalled, setApiCalled] = useState(false); // State to track API call

  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   // console.log(`akash-${permission}`);
  //   if (permission === "granted") {
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         "BGUJ3kg7JWAj5oKDGs5-MiKeQUbbM8O-YzcHMc5J4wOHZcTovqgtUiE70FwT1uyEmiS75Df14nbWvX5PvyR3oKU",
  //     });
  //     if (token) {
  //       console.log(`Token: ${token}`);
  //       localStorage.setItem("fcmToken", token);
  //       sendFcmToken(token);
  //     }
  //   } else if (permission === "denied") {
  //     alert("You have denied for the notification");
  //   }
  // }

  // useEffect(() => {
  //   requestPermission();
  // }, []);

  // useEffect(()=>{
  //        // ak
  //   const handleScroll = () => {
  //     const scrollTop = window.scrollY;
  //     const docHeight =
  //       document.documentElement.scrollHeight -
  //       document.documentElement.clientHeight;
  //     const scrollPercent = (scrollTop / docHeight) * 100;
  //     console.log(`akash-${scrollPercent}`);
  //     setScrollPercent(scrollPercent);
  //     if (scrollPercent > 70 && !apiCalled) {
  //       // console.log("bhavani");
  //       sendPushNotification();
  //       setApiCalled(true);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  //   // end ak
  // },[apiCalled])

  // async function sendFcmToken(token) {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/fcmtoken`,
  //       { token }
  //     );
  //     console.log("FCM token sent successfully:", response.data);
  //   } catch (error) {
  //     console.error("Error sending FCM token:", error);
  //   }
  // }

  // async function sendPushNotification() {
  //   const token = localStorage.getItem("fcmToken");
  //   if (!token) {
  //     console.error("No FCM token found");
  //     return;
  //   }
  //   const requestData = {
  //     messages: {
  //       notification: {
  //         title: "New Content Alert!",
  //         body: "You've scrolled more than 70% of the page!",
  //       },
  //       token: token,
  //     },
  //   };
  //   const response = await axios.post(
  //    `${process.env.REACT_APP_BASE_URL}/send`,
  //     requestData
  //   );
  //   if (response.status === 200) {
  //       // alert(`$result:${response}`);
  //   }
  // }

  // useEffect(() => {
  //   const unsubscribe = onMessage(messaging, (payload) => {
  //     console.log('Message received: ', payload);
  //     // Handle the incoming message here, for example, display a notification
  //     alert(`Notification received: ${payload.notification.title} - ${payload.notification.body}`);
  //   });

  //   return () => unsubscribe();
  // }, []);

  return (
    <Box
      minWidth="100%"
      sx={{
        background:
          "linear-gradient(180deg, rgba(187, 95, 245, 0.15) 0%, rgba(114, 124, 245, 0.02) 100%)",
      }}
    >
      <Header />
      <Box
        component={motion.div}
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.5, delay: 0.25 }}
        pt={"6.5rem"}
      >
        <Box
          width="100%"
          display={isNonMobileScreen ? "flex" : "block"}
          padding={"0.5rem 3%"}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
              // height:{xs:"300px",sm:"300px",md:"350px",lg:"350px"},
              display: "flex",
              justifyContent: "center",
              // alignItems:"center",
              // mt:1
            }}
          >
            <FigmaDiv />
          </Box>

          <Box mt={isNonMobileScreen ? "60px" : "120px"}>
            <ChoseFile />
            <Box display={isNonMobileScreen ? "block" : "none"}>
              <Arrow />
            </Box>
          </Box>
        </Box>
        <Box>
          <Blog />
        </Box>
        {/* <Box>
           <Button onClick={sendPushNotification}>
             akash
           </Button>
        </Box> */}
        <Footer />
      </Box>
    </Box>
  );
}

export default VideoCompressor;
