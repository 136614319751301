import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function Referral() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const linkId = params.get("linkId");
    if (linkId) {
      // Forward the request to the backend
      window.location.href = `${process.env.REACT_APP_BASE_URL}/refer/deeplink?linkId=${linkId}`;
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Join me on TechToolPlus!</title>
        {/* <meta property="og:title" content="Join me on TechToolPlus!" />
        <meta
          property="og:description"
          content="Join now with a special referral link and enjoy our Video compressor."
        />
        <meta property="og:image" content="https://techtoolplus.com/icon.png" />
        <meta property="og:url" content={window.location.href} />

        <meta name="twitter:title" content="Share this Awesome TechToolPlus!" />
        <meta
          name="twitter:description"
          content="Join now with a special referral link and enjoy our Video compressor."
        />
        <meta
          name="twitter:image"
          content="https://techtoolplus.com/icon.png"
        />
        <meta name="twitter:card" content="summary_large_image" /> */}
      </Helmet>

      <></>
    </>
  );
}

export default Referral;
