import React from "react";
import { Grid, Paper, Box, Typography, useTheme } from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CompressingProgressBar from "../FileUploadingPageComponents/CompressingProgressBar";
// import CustomLoader from "../FileUploadingPageComponents/CustomLoader";
import Loader from "../FileUploadingPageComponents/Loader";
import ShineText from "../StyledComponents/ShineText";
import VideoIcon from "../FileUploadingPageComponents/VideoIcon";

const Item = (props) => (
  <Paper
    sx={{
      justifyContent: "flex-start",
      padding: "16px 1rem",
      borderRadius: "0px",
      height: "60px",
      ...props.sx,
    }}
  >
    {props.children}
  </Paper>
);

function MobileCompressingComponents({
  multipleFilesSelected,
  fileUploadingProgress,
  fileProgress,
  uploadedFiles,
  handleRemoveFile,
}) {
  const theme = useTheme();
  return (
    <>
      <Grid
        container
        box-sizing="border-box"
        border="1px solid #F6F6F6"
        borderRadius="5px"
        sx={{overflow:fileProgress ?"hidden":"visible"}}
      >
        {/* akash */}
        {uploadedFiles.length > 0 &&
          uploadedFiles.map((file, index) => (
            <React.Fragment key={index}>
              <Grid item xs={8} border="none">
                <Item
                  sx={{
                    borderTopLeftRadius: "35px",
                    border: "none",
                    // background: "rgba(114, 124, 245, 0.10)",
                    color: "rgba(0, 0, 0, 0.50)",
                    boxShadow: "none",
                    // width:"200px",
                    // padding:"8px 0px 0px 12px"
                  }}
                >
                  <Box>
                  <img
                      src="../assets/video-icon.svg"
                      alt=""
                      style={{
                        color: "white",
                        height: "25px",
                        width: "50px",
                        objectFit: "cover",
                        borderRadius: "5px",
                        // border:"2px solid rgb(238,174,202)"
                      }}
                    />
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "400",
                        wordWrap: "break-word",
                        ml:"4px"
                      }}
                      variant={theme.typography.h5}
                    >
                      {file.name}
                    </Typography>
                  </Box>
                </Item>
              </Grid>

              <Grid item xs={4} border="none">
                <Item
                  sx={{
                    borderTopRightRadius: "33px",
                    border: "none",
                    boxShadow: "none",
                    // background: "rgba(114, 124, 245, 0.10)",
                    // color: "rgba(0, 0, 0, 0.50)",
                  }}
                >
                  {/* animation */}
                  <img
                    src="../assets/compressed.svg"
                    alt=""
                    style={{
                      color: "white",
                      height: "35px",
                      width: "90px",
                      objectFit: "fill",
                    }}
                  />
                  {/* end animation */}
                </Item>
              </Grid>
            </React.Fragment>
          ))}
        {/* end akash */}
        {multipleFilesSelected.length > 0 &&
          multipleFilesSelected.map((file, index) => (
            <React.Fragment key={index}>
              <Grid item xs={10} border="none">
                <Item
                  sx={{
                    border: "none",
                    color: "rgba(0, 0, 0, 0.50)",
                    boxShadow: "none",
                  }}
                >
                  <Box>
                    <img
                      src="../assets/video-icon.svg"
                      alt=""
                      style={{
                        color: "white",
                        height: "25px",
                        width: "50px",
                        objectFit: "cover",
                        borderRadius: "5px",
                        // border:"2px solid rgb(238,174,202)"
                      }}
                    />
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "400",
                        wordWrap: "break-word",
                        ml:"4px"
                      }}
                      variant={theme.typography.h5}
                    >
                      {file.name}
                    </Typography>
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={2} border="none">
                <Item
                  sx={{
                    color: "rgba(0, 0, 0, 0.50)",
                    border: "none",
                    boxShadow: "none",
                  }}
                  // onClick={handleRemoveFile(index)}
                  // onClick={() => removeFile(index)}
                >
                  <Box>
                    <CancelRoundedIcon
                      sx={{
                        fontSize: "26px",
                        transition: "transform 0.3s ease-in-out",
                        "&:hover": {
                          cursor: "pointer",
                          color: "red",
                          transform: "scale(1.2)",
                        },
                      }}
                      onClick={() => handleRemoveFile(index)}
                    />
                  </Box>
                </Item>
              </Grid>

              <Grid item xs={12} border="none">
                <Item
                  sx={{
                    border: "none",
                    boxShadow: "none",
                    // background: "rgba(114, 124, 245, 0.10)",
                    // color: "rgba(0, 0, 0, 0.50)",
                  }}
                >
                  {/* <CompressingProgressBar
                        progress={fileUploadingProgress[file.name]}
                      />

                      <CompressingProgressBar
                        progress={fileProgress[file.name]}
                      /> */}

                  {/* {fileUploadingProgress[file.name] !== undefined ? (
                        <CompressingProgressBar
                          progress={fileUploadingProgress[file.name] || 0} 
                        />
                      ) : (
                        <CustomLoader />
                      )} */}
                  {fileProgress[file.name] !== undefined ? (
                    <CompressingProgressBar
                      progress={fileProgress[file.name] || 0}
                    />
                  ) : (
                    <Loader />
                  )}
                </Item>
              </Grid>
            </React.Fragment>
          ))}
        <Grid item xs={12} border="none">
          <Item
            sx={{
              background: "#EEEEEE",
              color: "rgba(0, 0, 0, 0.50)",
              border: "none",
              boxShadow: "none",
            }}
          >
            <Typography
              variant={theme.typography.h6}
              sx={{
                wordWrap: "break-word",
                fontWeight: "400",
                color: "#393E46",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Compressed {uploadedFiles.length} Files
            </Typography>
          </Item>
        </Grid>
        <Grid
          item
          xs={12}
          border="none"
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Item
            sx={{
              background: "rgba(114, 124, 245)",
              border: "none",
              boxShadow: "none",
            }}
          >
            <Typography
              variant={theme.typography.h6}
              sx={{
                wordWrap: "break-word",
                fontWeight: "400",
                color: "white",
                display: "flex",
                justifyContent: "center",
              }}
            >
              please wait...
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}

export default MobileCompressingComponents;
