import React from "react";
import { Box, Typography } from "@mui/material";
import VideoComponents from "../FileUploadingPageComponents/VideoComponents";
import DoneComponents from "../FileUploadingPageComponents/DoneComponents";
import StartOverComponents from "../FileUploadingPageComponents/StartOverComponents";

function MobileCompressedComponents({ compressedResult }) {
  return (
    <Box>
      {compressedResult.map((fileObject, index) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            background: "white",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
            borderRadius: "20px",
            padding: "1rem 6%",
          }}
          mt={1}
          key={index}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: "#FF585C",
              fontSize: "16px",
              fontWeight: "400",
              fontFamily:"inherit"
            }}
          >
            Compressed by 50%
          </Typography>
          <Box
            sx={{
              mt: "10px",
            }}
          >
            <VideoComponents videoUrl={fileObject.file.url} />
          </Box>
          <Box mt={1}>
            <DoneComponents
              filename={fileObject.file.filename}
              originalfilesize={fileObject.file.originalFileSize}
              compressedfilesize={fileObject.file.compressedFileSize}
              finalUrl={fileObject.file.url}
            />
          </Box>
        </Box>
      ))}
      {/* <StartOverComponents /> */}
    </Box>
  );
}

export default MobileCompressedComponents;
